import {
  Button,
  Select,
  SelectOptionType,
  TextInput,
  addToast,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import {
  getTariffQuotasSimulation,
  saveTariffInfo,
} from '../../../../../api/requests/tuitionProcess';
import { useParameters } from '../../../../../hooks/useParameters';
import { useStepState } from '../../../../../hooks/useStepState';
import { useValidations } from '../../../../../hooks/useValidations';
import {
  InterestsCalculatorVariant,
  QuotaSimulationItem,
  TariffData,
  TariffPaymentMethodSettingValue,
  TariffPaymentTypeCodesEnum,
} from '../../../../../types/tariffTypes';
import { SustainerType } from '../../../../../utils/selectOptions';
import TariffDisplayData from './TariffDisplayData';
import TariffTable from './TariffTable';

interface TariffFormProps {
  tariffData: TariffData;
  //Desencadena el refresco de la vista al dar un descuento
  refresh?: () => void;
  //Valores guardados en el formulario
  defaultValues: DefaultValues<any>;
  //Opciones para select
  selectOptions: Record<string, SelectOptionType[]>;
  settings: {
    dateLocalOffset: string;
    paydaySetting: { value: number; description: string };
    paymentMethodSettingValue: TariffPaymentMethodSettingValue;
    tuitionFree: boolean;
  };
}

export default function TariffForm(props: TariffFormProps) {
  const { tariffData, defaultValues, selectOptions, settings } = props;
  const prefix = 'tuitionProcessNoSua.tariffQuotas';
  const methods = useForm({ defaultValues });
  const { cityOptions } = useParameters();
  const { handleSubmit, control, watch, setValue } = methods;
  const { t } = useTranslation();
  const { nextStep } = useStepState();
  const { msgValidations } = useValidations();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quotas, setQuotas] = useState<QuotaSimulationItem[]>([]);
  const [activityCodeError, setActivityCodeError] = useState<string>();
  const activityCode: string | undefined = watch('economicActivityCode');
  const quota: SelectOptionType = watch('quota');
  const documentType: SelectOptionType = watch('documentType');
  const tariffPaymentType: SelectOptionType & {
    value: TariffPaymentTypeCodesEnum;
  } = watch('tariffPaymentType');
  const tariffPaymentMethod: SelectOptionType & {
    value: string;
  } = watch('paymentMethod');

  const finalTariff = tariffData.amountsWithDiscounts.feeNetAmount;

  const tuitionValue = useMemo(
    () =>
      tariffData.amountsWithDiscounts.shouldPayTuition
        ? tariffData.amountsWithDiscounts.tuitionNetAmount
        : 0,
    [tariffData.amountsWithDiscounts],
  );

  const paymentMethodOptions = useMemo(
    () =>
      selectOptions.paymentMethodsOptions.filter(
        (item) => item.paymentType === tariffPaymentType?.value,
      ),
    [selectOptions.paymentMethodsOptions, tariffPaymentType?.value],
  );

  const quotasEnabled = useMemo(() => {
    // Las cuotas solo son seleccionables cuando es a credito. Si es contado se establece 1 cuota con el total
    if (!tariffPaymentType?.value) {
      return false;
    }
    return [
      TariffPaymentTypeCodesEnum.CR,
      TariffPaymentTypeCodesEnum.CRI,
    ].includes(tariffPaymentType?.value);
  }, [tariffPaymentType?.value]);

  useEffect(() => {
    if (tariffPaymentType?.value === TariffPaymentTypeCodesEnum.CO) {
      setValue('quota', selectOptions.quotaOptions[0]);
    }
  }, [selectOptions.quotaOptions, setValue, tariffPaymentType?.value]);

  const onSubmit = useCallback(
    async (values) => {
      setIsSubmitting(true);
      const economicActivity = values?.economicActivityCode
        ?.trim()
        ?.split(' ')[0];
      const activity = selectOptions?.businessActivitiesOptions?.find(
        (e) => e?.value === economicActivity,
      );
      const { data, error } = await saveTariffInfo({
        documentTypeCode: values.documentType?.value,
        paymentMethodCode: values.paymentMethod?.value,
        tariffPaymentTypeCode: values.tariffPaymentType?.value,
        quotaId: values.quota?.value,
        businessActivity: values.businessActivity?.value,
        economicActivityCode: activity?.value,
        businessCity:
          values.businessCity?.value && parseInt(values.businessCity?.value),
      });

      if (data) {
        addToast({
          icon: 'check',
          color: 'success',
          text: t(`${prefix}.successOnSaveTariff`),
        });
        nextStep();
      }
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.errorOnSaveTariff`),
        });
      }
      setIsSubmitting(false);
    },
    [selectOptions?.businessActivitiesOptions, t, nextStep],
  );

  useEffect(() => {
    getTariffQuotasSimulation({
      tariff: finalTariff,
      numberOfInstalment: Number(quota?.label ?? 0),
      payday: settings.paydaySetting?.value,
      tariffPaymentType: tariffPaymentType?.value,
      tariffPaymentMethod: tariffPaymentMethod?.value,
      tariffDownPayment: 0,
      matriculation: tuitionValue,
    })
      .then(({ data }) => setQuotas(data ?? []))
      .catch(() => setQuotas([]));
    //
  }, [
    tuitionValue,
    quota?.label,
    settings.paydaySetting?.value,
    tariffPaymentType?.value,
    tariffPaymentMethod?.value,
    finalTariff,
  ]);

  const handleBlurActivityCode = useCallback(async () => {
    const code = activityCode?.split(' ')[0];
    const activity = selectOptions?.businessActivitiesOptions?.find(
      (e) => e?.value === code,
    );
    if (code?.trim()) {
      if (!activity) {
        setActivityCodeError(
          t(`${prefix}.displayData.invalidEconomicActivityCode`),
        );
      } else {
        setActivityCodeError(undefined);
        setValue(
          'economicActivityCode',
          `${code?.trim()} ${activity?.label?.trim()}`,
        );
      }
    } else {
      setActivityCodeError(undefined);
    }
  }, [activityCode, selectOptions?.businessActivitiesOptions, setValue, t]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TariffDisplayData
          tariffData={tariffData}
          paydayDescription={settings.paydaySetting.description}
          showTuitionAmount={settings.paymentMethodSettingValue.hasTuition}
          tuitionValue={tuitionValue}
        >
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <Select
                name="tariffPaymentType"
                label={t(`${prefix}.displayData.tariffPaymentType`)}
                isClearable={false}
                options={selectOptions.tariffPaymentTypesOptions}
                control={control}
                rules={{ required: msgValidations.required }}
                onInputChange={() => {
                  setValue('paymentMethod', null);
                }}
              />
            </Col>
            {/* los inputs a continuación solo se muestran si la setting es true */}
            {Boolean(settings.paymentMethodSettingValue.hasPaymentMethod) ? (
              <Col className="pb-3" xs={12} lg={4}>
                <Select
                  name="paymentMethod"
                  label={t(`${prefix}.displayData.paymentMethod`)}
                  isClearable={false}
                  options={paymentMethodOptions}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
            ) : null}

            {!!quotasEnabled ? (
              <Col className="pb-3" xs={12} lg={4}>
                <Select
                  name="quota"
                  label={t(`${prefix}.displayData.quotas`)}
                  isClearable={false}
                  options={selectOptions.quotaOptions}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
            ) : null}

            {/* los inputs a continuación solo se muestran si es una persona jurídica*/}
            {tariffData.sustainer.type === SustainerType.LegalPerson ? (
              <>
                <Col className="pb-3" xs={12} lg={4}>
                  <Select
                    name="documentType"
                    label={t(`${prefix}.displayData.documentType`)}
                    isClearable={false}
                    options={selectOptions.documentTypesOptions}
                    control={control}
                    rules={{ required: msgValidations.required }}
                  />
                </Col>
                {documentType?.value === 'FA' ? (
                  <>
                    <Col className="pb-3" xs={12} lg={4}>
                      {settings.paymentMethodSettingValue
                        ?.businessActivityStyle === 'text' ? (
                        <div className="w-100">
                          <TextInput
                            name="economicActivityCode"
                            label={t(
                              `${prefix}.displayData.economicActivityCode`,
                            )}
                            control={control}
                            onBlur={handleBlurActivityCode}
                            errorText={activityCodeError}
                          />
                          <p className="text-light fw-400">
                            <Trans
                              i18nKey={`${prefix}.displayData.economicActivityCodeDescription`}
                              components={{
                                linked: (
                                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                                  <a
                                    rel="noreferrer"
                                    href="https://www.sii.cl/ayudas/ayudas_por_servicios/1956-codigos-1959.html"
                                    target="_blank"
                                    className="text-primary"
                                  />
                                ),
                              }}
                            />
                          </p>
                        </div>
                      ) : (
                        <Select
                          name="businessActivity"
                          label={t(`${prefix}.displayData.businessActivity`)}
                          isClearable={false}
                          options={selectOptions.businessActivitiesOptions}
                          control={control}
                          rules={{ required: msgValidations.required }}
                        />
                      )}
                    </Col>
                    <Col className="pb-3" xs={12} lg={4}>
                      <Select
                        name="businessCity"
                        label={t(`${prefix}.displayData.businessCity`)}
                        isClearable={false}
                        options={cityOptions}
                        control={control}
                        rules={{ required: msgValidations.required }}
                      />
                    </Col>
                  </>
                ) : null}
              </>
            ) : null}
          </>
        </TariffDisplayData>

        <TariffTable
          quotas={quotas}
          showInterestsDetails={
            settings.paymentMethodSettingValue?.interestCalculatorVariant ===
            InterestsCalculatorVariant.SkipInterest
          }
        />
        <Row className="py-5 justify-content-end">
          <Col xs={12} lg={4} className="text-white">
            <Button
              type="submit"
              text={t(`${prefix}.btnNext`)}
              disabled={isSubmitting}
              loading={isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}
