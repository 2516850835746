import { SustainerStepResponse } from '../../../../../types/sustainerTypes';

export const businessFieldMappingSustainerFile: { [key: string]: any } = {
  businessRut: 'rut',
  businessName: 'names',
  businessAddressStreet: 'addressStreet',
  businessAddressNumber: 'addressNumber',
  businessAddressExtra: 'addressExtra',
  businessCountry: 'country',
  businessRegion: 'region',
  businessCommune: 'commune',
  businessPhone: 'phone',
  businessCellPhone: 'cellPhone',
  businessEmail: 'email',
  // Dermik
  businessLocation: 'location',
  businessCustomCity: 'customCity',
};

export default function sustainerFileFormatPreviusData(
  data: SustainerStepResponse,
) {
  const businessFormattedData: { [key: string]: any } = {};
  Object.keys(businessFieldMappingSustainerFile).forEach((key) => {
    businessFormattedData[key] =
      data?.responses[businessFieldMappingSustainerFile[key]] ?? '';
  });

  return {
    ...data.responses,
    ...businessFormattedData,
  };
}
