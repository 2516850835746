import { Redirect, useParams } from 'react-router-dom';

import { IS_SUA } from '../../config/constants';
import RegistrationNoSuaLoader from './parts/RegistrationNoSuaLoader';
import RegistrationForm from './parts/RegistrationForm';

const RegistrationNoSua = () => {
  const { studyPlanVersionOfferPeriodAdmissionId: carrerId } = useParams<{
    studyPlanVersionOfferPeriodAdmissionId: string;
  }>();

  if (IS_SUA) {
    return <Redirect to="/auth" />;
  }

  if (!carrerId) {
    return <RegistrationForm />;
  }

  return (
    <RegistrationNoSuaLoader studyPlanVersionOfferPeriodAdmissionId={carrerId}>
      {({ data }) => {
        return <RegistrationForm career={data} />;
      }}
    </RegistrationNoSuaLoader>
  );
};

export default RegistrationNoSua;
