import React, { FunctionComponent, createContext, useReducer } from 'react';
import Loading from '../components/info/Loading';
import { useI18nFullFilled } from '../hooks/useI18nFullFilled';

import userReducer, { initialState } from '../reducers/userReducer';
import { UserContextType } from '../types/userTypes';

export const UserContext = createContext({} as UserContextType);

const UserProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const i18FullFilled = useI18nFullFilled();

  if (!i18FullFilled) {
    return (
      <div className="vh-100 d-flex">
        <div className="m-auto">
          <Loading textBody=" " />
        </div>
      </div>
    );
  }

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
