import {
  Button,
  CheckInput,
  DateInput,
  Select,
  SelectOptionType,
  SingleCardContainer,
  TextInput,
  addToast,
  showDialogInfo,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import { clean, format } from 'rut.js';

import {
  requestSavePostulant,
  requestSavePostulantAndCareer,
} from '../../../api/requests/tuitionProcess';
import { ReactComponent as AddemicProductLogo } from '../../../assets/addemic/logo_expanded.svg';
import { ReactComponent as CampusProductLogo } from '../../../assets/campus/logo_expanded.svg';
import { IS_ADDEMIC } from '../../../config/constants';
import { useParameters } from '../../../hooks/useParameters';
import { useUserState } from '../../../hooks/useUserState';
import { useValidations } from '../../../hooks/useValidations';
import { TranslationsKeys } from '../../../locales/translations';
import { SavePostulantBody } from '../../../types/postulantTypes';
import {
  StudyPlanQuotaResponse,
  TuitionOriginCodesEnum,
} from '../../../types/tuitionProcessResponseTypes';
import { setJWT } from '../../../utils/auth';
import { formatDate } from '../../../utils/dates';
import RegistrationModalError, {
  RegistrationModalErrorMethods,
} from './RegistrationModalError';

export type RegistrationInputs = {
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  birthday: Date;
  hasPassport: boolean;
  rut: string;
  passportNumber: string;
  passportCountry: SelectOptionType;
  phone: string;
  email: string;
  studyPlan: string;
};

function RegistrationWithCareerForm({
  career,
}: {
  career?: StudyPlanQuotaResponse;
}) {
  const modalErrorRef = useRef<RegistrationModalErrorMethods>(null);
  //hooks
  const { state: defaultValues } = useLocation<Partial<RegistrationInputs>>();
  const { setIsLogged, setStatusPostulation } = useUserState();
  const { t } = useTranslation(TranslationsKeys.AUTH_REGISTER);
  const methods = useForm<RegistrationInputs>({ defaultValues });
  const { control, watch, handleSubmit } = methods;
  const history = useHistory();
  const { countryOptions } = useParameters();
  const {
    msgValidations,
    validateRut,
    validateTextNotEmpty,
    validatePhone,
    validateEmail,
  } = useValidations();
  const hasPassport = watch('hasPassport');

  const [confirm, setConfirm] = useState(!career?.studyPlanVersion?.id);
  const [submiting, setSubmiting] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);

  const hasCareer = useMemo(
    () => !!career?.studyPlanVersion?.id,
    [career?.studyPlanVersion?.id],
  );

  const notAvailableTuition = useMemo(
    () =>
      career?.scopes &&
      !career?.scopes?.includes(TuitionOriginCodesEnum.Online),
    [career?.scopes],
  );

  const formatBodyData = useCallback(
    (values: RegistrationInputs, acceptsWaitingList = false) => {
      const body: SavePostulantBody = {
        type: values.hasPassport ? 'passport' : 'rut',
        names: values.names,
        rut: values.rut ? clean(values.rut) : undefined,
        passportNumber: values?.passportNumber,
        passportCountry: values?.passportCountry?.value
          ? values.passportCountry.value.toString()
          : undefined,
        birthday: formatDate(values.birthday, 'YYYY-MM-DD'),
        paternalLastName: values.paternalLastName,
        maternalLastName: values.maternalLastName,
        paternalPreference: true,
        email: values.email,
        phone: values.phone,
      };

      if (hasCareer) {
        return {
          ...body,
          admissionId: career?.id,
          acceptsWaitingList,
        };
      }

      return body;
    },
    [career?.id, hasCareer],
  );

  /**
   * Se utiliza para inscribir al alumno en lista de espera luego de confirmar.
   */
  const acceptWaitingList = useCallback(async () => {
    if (confirming || !hasCareer) {
      return;
    }
    setConfirming(true);
    const { data } = await requestSavePostulantAndCareer(
      formatBodyData(methods.getValues(), true),
    );
    if (data) {
      setJWT(data.accessToken);
      setIsLogged(true);
    }

    setConfirming(false);
    modalErrorRef?.current?.open('QUOTA_EXCEEDED');
  }, [confirming, hasCareer, formatBodyData, methods, setIsLogged]);

  const handleRedirectToRegularRegistration = useCallback(
    (values?: RegistrationInputs) => {
      return history.push(
        `/auth/registration`,
        values
          ? {
              rut: values.rut ? format(values.rut!) : null,
              hasPassport: values.hasPassport,
              passportNumber: values.passportNumber,
              passportCountry: values.passportCountry,
            }
          : undefined,
      );
    },
    [history],
  );

  const showNotAvailableTuition = useCallback(
    (values?: RegistrationInputs) => {
      showDialogInfo({
        icon: {
          name: 'information',
          color: 'primary',
        },
        title: t(`modalNotAvailableTuition.title`),
        subtitle: t(`modalNotAvailableTuition.subtitle`, {
          phone: career?.helpPhone,
          email: career?.helpEmail,
        }),
        btnConfirm: {
          text: t(`modalNotAvailableTuition.btn`),
          color: 'primary',
          onConfirm: () => {
            handleRedirectToRegularRegistration(values);
          },
        },
      });
    },
    [
      career?.helpEmail,
      career?.helpPhone,
      handleRedirectToRegularRegistration,
      t,
    ],
  );

  const onSubmit = useCallback(
    async (values: RegistrationInputs) => {
      if (submiting) {
        return;
      }
      if (hasCareer && notAvailableTuition) {
        return showNotAvailableTuition(values);
      }
      setSubmiting(true);
      const requestParams = formatBodyData(values);

      const requestFunction = hasCareer
        ? requestSavePostulantAndCareer
        : requestSavePostulant;

      const { data, error } = await requestFunction(requestParams);

      if (data && !error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`saveSuccess`),
        });
        setJWT(data.accessToken);
        setIsLogged(true);
        setSubmiting(false);
        if (requestParams.acceptsWaitingList) {
          setStatusPostulation('IN_WAITING_LIST');
        }
      } else {
        setSubmiting(false);
        modalErrorRef?.current?.open(error ? error?.data?.message : undefined);
      }
    },
    [
      submiting,
      hasCareer,
      notAvailableTuition,
      t,
      formatBodyData,
      setIsLogged,
      setStatusPostulation,
      showNotAvailableTuition,
    ],
  );

  const backToLogin = useCallback(() => {
    history.push('/auth/tuition-process');
  }, [history]);

  const onLogin = useCallback(
    (values: RegistrationInputs) => {
      history.push('/auth/tuition-process', {
        rut: values.rut ? format(values.rut!) : null,
        hasPassport: values.hasPassport,
        passportNumber: values.passportNumber,
        passportCountry: values.passportCountry,
      });
    },
    [history],
  );

  useEffect(() => {
    if (hasCareer && notAvailableTuition) {
      showNotAvailableTuition();
    }
  }, [hasCareer, notAvailableTuition, showNotAvailableTuition]);

  return (
    <>
      <SingleCardContainer
        brandLogoColor={
          IS_ADDEMIC ? <AddemicProductLogo /> : <CampusProductLogo />
        }
      >
        <div className="d-flex justify-content-center">
          {IS_ADDEMIC ? (
            <AddemicProductLogo style={{ maxHeight: 80 }} />
          ) : (
            <img
              src="/tenant/logo_expanded.svg"
              alt="tenant_logo"
              style={{ maxHeight: 100 }}
            />
          )}
        </div>

        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row className="px-4 pt-4">
            <Col xs={12} className="pt-2 pb-4 text-center">
              <p>{t(`form.body`)}</p>
            </Col>
            {!hasPassport ? (
              <Col xs={12}>
                <TextInput
                  name="rut"
                  label={t(`form.rut`)}
                  control={control}
                  formatter="rut"
                  shouldUnregister={true}
                  rules={{
                    validate: {
                      empty: validateTextNotEmpty,
                      rut: validateRut,
                    },
                  }}
                  readOnly={Boolean(defaultValues)}
                />
              </Col>
            ) : (
              <>
                <Col xs={12} md={6} className="pr-md-1">
                  <Select
                    name="passportCountry"
                    label={t(`form.passportCountry`)}
                    control={control}
                    options={countryOptions}
                    shouldUnregister={true}
                    isClearable={false}
                    rules={{ required: msgValidations.required }}
                    disabled={Boolean(defaultValues)}
                  />
                </Col>
                <Col xs={12} md={6} className="pl-md-1">
                  <TextInput
                    name="passportNumber"
                    label={t(`form.passportNumber`)}
                    control={control}
                    shouldUnregister={true}
                    rules={{ validate: validateTextNotEmpty }}
                    readOnly={Boolean(defaultValues)}
                  />
                </Col>
              </>
            )}

            <Col xs={12}>
              <CheckInput
                name="hasPassport"
                label={'Ingresar Pasaporte'}
                control={control}
                disabled={Boolean(defaultValues)}
              />
            </Col>

            {hasCareer && (
              <Col xs={12}>
                <TextInput
                  name="studyPlan"
                  label={t(`form.studyPlan`)}
                  disabled
                  value={career?.studyPlanVersion?.name}
                />
              </Col>
            )}
            <Col xs={12}>
              <TextInput
                name="names"
                label={t(`form.names`)}
                control={control}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="paternalLastName"
                label={t(`form.paternalLastName`)}
                control={control}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="maternalLastName"
                label={t(`form.maternalLastName`)}
                control={control}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>

            <Col xs={12}>
              <DateInput
                name="birthday"
                label={t(`form.birthday`)}
                control={control}
                rules={{ required: msgValidations.required }}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="phone"
                label={t(`form.phone`)}
                control={control}
                formatter="phone"
                rules={{
                  validate: {
                    notEmpty: validateTextNotEmpty,
                    phone: validatePhone,
                  },
                }}
                placeholder={t(`common.placeholder.cellphone`)}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="email"
                label={t(`form.email`)}
                control={control}
                rules={{
                  validate: {
                    notEmpty: validateTextNotEmpty,
                    email: validateEmail,
                  },
                }}
              />
            </Col>
            {hasCareer && (
              <Col xs={12}>
                <CheckInput
                  name="confirm"
                  label={t(`form.acceptStudyPlanTerms`)}
                  value={confirm}
                  onChange={() => setConfirm((old) => !old)}
                />
              </Col>
            )}
            <Col xs={12} className="pt-4">
              <Button
                type="submit"
                text={t(`form.registrationBtn`)}
                fullwidth
                loading={methods.formState.isSubmitting}
                disabled={!confirm}
              />
            </Col>
          </Row>
        </Form>
        <Row>
          <Col xs={12} className="pt-4 text-center">
            <Link to="/auth" className="text-link">
              {t(`goBackToLogin`)}
            </Link>
          </Col>
        </Row>
      </SingleCardContainer>

      <RegistrationModalError
        ref={modalErrorRef}
        loading={confirming}
        hasPassport={hasPassport}
        onBackToLogin={backToLogin}
        onLogin={handleSubmit(onLogin)}
        onGoToWaitlist={acceptWaitingList}
      />
    </>
  );
}

export default RegistrationWithCareerForm;
