import { SustainerType } from '../utils/selectOptions';
import { StudyPlanVersionOffer } from './studyPlan';

interface Postulation {
  id: number;
  period?: { id: number; tuitionPeriod?: { id: number; value: number } };
}

interface PaymentDate {
  id: number;
  index: number;
  description: string;
  postulationDetailId: number;
  amount: number;
  interests: number;
  startDate: string;
  endDate: string;
  amountWithoutInterests: number;
}

interface PostulationDetail {
  id: number;
  postulation?: Postulation;
  paymentDates?: PaymentDate[];
}

interface Admission {
  id: number;
  postulationsDetail?: PostulationDetail[];
}
export interface QuotaSimulationItem {
  startDate: string;
  endDate: string;
  index: number;
  amount: number;
  interests: number;
  description: string;
}

interface CustomStudyPlan {
  id: number;
  academicOfferId: number;
  studyPlanVersionOfferId: number;
  quota: number;
  createdAt: Date;
  updatedAt: Date;
  studyPlanVersionOffer: StudyPlanVersionOffer;
  tariff: { value: number; hasDiscount: boolean };
  admissions?: Admission[];
}

export enum DiscountTypes {
  Tuition = 'tuition',
  Tariff = 'tariff',
}

export interface AmountsWithDiscounts {
  shouldPayTuition: boolean;
  tuitionAmount: number;
  tuitionDiscountsAmount: number;
  tuitionNetAmount: number;
  feeAmount: number;
  feeDiscountsAmount: number;
  feeNetAmount: number;
  discounts: {
    code: string;
    name: string;
    type: DiscountTypes;
    percentage: number;
    amount: number;
  }[];
}

export interface TariffData {
  studyPlan: CustomStudyPlan;
  sustainer: {
    type: SustainerType;
    businessActivityId?: string;
    businessCityId?: number;
  };
  postulationDetail: {
    documentTypeCode?: string;
    economicActivityCode?: string;
    paymentMethodCode?: string;
    tariffPaymentTypeCode?: string;
    paymentDates: unknown[];
  };
  amountsWithDiscounts: AmountsWithDiscounts;
}

interface Base {
  id: string;
  name: string;
  description?: string;
}

export interface Quota extends Base {
  quota: string;
}

export enum InterestsCalculatorVariant {
  ProrateInterest = 'proratear',
  SkipInterest = 'saltar',
}

export interface TariffPaymentMethodSettingValue {
  businessActivityStyle?: 'text' | 'select';
  hasTuition: boolean;
  hasPaymentMethod: boolean;
  interestRate: number;
  interestCalculatorVariant: InterestsCalculatorVariant;
  noInterestsInstallments: number;
}

interface PaymentMethod extends Base {
  modalityToPaymentMethod: {
    paymentMethodId: string;
    paymentType: string;
    tuitionOrigin: string;
  }[];
}

export interface TariffFormData {
  //opciones para select
  quotas: Quota[];
  paymentMethods: PaymentMethod[];
  tariffPaymentTypes: Base[];
  documentTypes: Base[];
  businessActivities: Base[];
  //fecha del servidor
  dateLocalOffset: string;
  //setting fecha de pago
  paydaySetting: { value: number; description: string };
  //valor setting metodos de pago
  paymentMethodSettingValue: TariffPaymentMethodSettingValue;
  isOnlineTuition: boolean;
  tuitionFree: boolean;
}

export enum TariffPaymentTypeCodesEnum {
  CO = 'CO', //contado
  CO3 = 'CO3', //contado
  CR = 'CR', //credito
  CRI = 'CRI', //credito indirecto
}
